<template>
<div @keyup="updateParent()">
	<div class="section-heading">Contact Details</div>
	<div class="two-columns">
		<div>
			<LabelRequired label="First Name" :condition="requiredValue('first_name')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.first_name">
		</div>
		<div>
			<LabelRequired label="Middle Name" :condition="requiredValue('middle_name')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.middle_name">
		</div>
		<div>
			<LabelRequired label="Last Name" :condition="requiredValue('last_name')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.last_name">
		</div>
		<div>
			<LabelRequired label="Suffix" :condition="requiredValue('suffix')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.suffix">
		</div>
	</div>
	<LabelRequired label="Company" :condition="requiredValue('company_name')"/>
	<input :disabled="disabled" type="text" v-model="contactCopy.company_name">
	<LabelRequired label="Address Line 1" :condition="requiredValue('address_1')"/>
	<input :disabled="disabled" type="text" v-model="contactCopy.address_1">
	<LabelRequired label="Address Line 2" :condition="requiredValue('address_2')"/>
	<input :disabled="disabled" type="text" v-model="contactCopy.address_2">
	<LabelRequired label="City" :condition="requiredValue('city')"/>
	<input :disabled="disabled" type="text" v-model="contactCopy.city">
	<div class="two-columns">
		<div>
			<LabelRequired label="State" :condition="requiredValue('state')"/>
			<StateSelect :disabled="disabled" :selectedState="contactCopy.state" showProvinces="true" placeHolder="Select..." @updateSelected="updateSelectedState"/>
		</div>
		<div>
			<LabelRequired label="Postal Code" :condition="requiredValue('postal_code')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.postal_code">
		</div>
	</div>
	<div class="two-columns">
		<div>
			<LabelRequired label="Email Address" :condition="requiredValue('email_address')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.email_address">
		</div>
		<div>
			<LabelRequired label="Phone Number" :condition="requiredValue('phone_number')"/>
			<input :disabled="disabled" type="text" v-model="contactCopy.phone_number">
		</div>
	</div>
</div>

</template>

<script>
import { mapActions } from 'vuex'
import LabelRequired from '@/components/LabelRequired.vue'
import StateSelect from '@/components/StateSelect.vue'
export default {
	name: 'contact-detail-form',
	props: ['contact', 'required'],
    components: {
        LabelRequired,
        StateSelect
    },
	data() {
		return {
			contactCopy: {},
			saving: false,
			saved: false
		}
	},
    created() {
		this.contactCopy = this.copyObject(this.contact)
	},
    watch: {
		contact(newVal) {
			if (newVal) {
				this.contactCopy = this.copyObject(this.contact)
			}
		}
	},
	computed: {
    	changed() {
    		return this.objectChanged(this.contact, this.contactCopy)
    	},
    	disabled() {
    		return false // TODO: granular user permissions for editing a contact
    	},
    	isProfile() {
	    	return this.$route.path.indexOf('profile') > -1 || true
    	},
		saveable() {
			return this.addressNotRequired || (this.contactCopy.address_1 && this.contactCopy.city && this.contactCopy.state && this.contactCopy.postal_code)
		}
    },
	methods: {
        ...mapActions([
            'apiCall', // map `this.apiCall()` to `this.$store.dispatch('apiCall')`
        ]),
		requiredValue(which) {
			if (this.required.indexOf(which) == -1 || (this.required.indexOf(which) > -1 && this.contactCopy[which])) {
				return true
			}
			return false
		},
		updateParent() {
			this.$emit('update', this.contactCopy);
		},
		updateSelectedState(state) {
			this.contactCopy.state = state
		}
	}
}
</script>
