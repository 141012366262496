<template>
<select v-model="localSelectedState" @change="$emit('updateSelected', localSelectedState)" :disabled="disabled">
    <option value="">{{ placeHolder }}</option>
    <option v-for="(state, abbr) in states" :value="abbr">{{ (showAbbr) ? abbr + ' - ' + state : state }}</option>
    <optgroup label="APO" v-if="showApo">
        <option v-for="(apo, abbr) in apo" :value="abbr">{{ (showAbbr) ? abbr : apo }}</option>
    </optgroup>
    <optgroup label="Provinces" v-if="showProvinces">
        <option v-for="(province, abbr) in provinces" :value="abbr">{{ (showAbbr) ? abbr + ' - ' + province : province }}</option>
    </optgroup>
</select>
</template>

<script>
export default {
    props: {
        selectedState: {
            type: String,
            default: ''
        },
        showAbbr: {
            type: [String, Boolean],
            default: true
        },
        placeHolder: {
	        type: String,
	        default: 'State...'
	    },
        disabled: {
            type: Boolean,
            default: false
        },
        showApo: {
            type: Boolean,
            default: false
        },
        showProvinces: {
            type: [String, Boolean],
            default: false
        }
    },
    watch: {
        selectedState(val) {
            this.localSelectedState = val
        }
    },
    data () {
        return {
            localSelectedState: this.selectedState,
            states: {
                "AL": "Alabama",
                "AK": "Alaska",
                "AZ": "Arizona",
                "AR": "Arkansas",
                "CA": "California",
                "CO": "Colorado",
                "CT": "Connecticut",
                "DE": "Delaware",
                "DC": "District Of Columbia",
                "FL": "Florida",
                "GA": "Georgia",
                "HI": "Hawaii",
                "ID": "Idaho",
                "IL": "Illinois",
                "IN": "Indiana",
                "IA": "Iowa",
                "KS": "Kansas",
                "KY": "Kentucky",
                "LA": "Louisiana",
                "ME": "Maine",
                "MD": "Maryland",
                "MA": "Massachusetts",
                "MI": "Michigan",
                "MN": "Minnesota",
                "MS": "Mississippi",
                "MO": "Missouri",
                "MT": "Montana",
                "NE": "Nebraska",
                "NV": "Nevada",
                "NH": "New Hampshire",
                "NJ": "New Jersey",
                "NM": "New Mexico",
                "NY": "New York",
                "NC": "North Carolina",
                "ND": "North Dakota",
                "OH": "Ohio",
                "OK": "Oklahoma",
                "OR": "Oregon",
                "PA": "Pennsylvania",
                "PR": "Puerto Rico",
                "RI": "Rhode Island",
                "SC": "South Carolina",
                "SD": "South Dakota",
                "TN": "Tennessee",
                "TX": "Texas",
                "UT": "Utah",
                "VT": "Vermont",
                "VA": "Virginia",
                "WA": "Washington",
                "WV": "West Virginia",
                "WI": "Wisconsin",
                "WY": "Wyoming"
            },
            apo: {
                "AA": "AA",
                "AE": "AE",
                "AP": "AP"
            },
            provinces: {
                "AB": "Alberta",
                "BC": "British Columbia",
                "MB": "Manitoba",
                "NB": "New Brunswick",
                "NL": "Newfoundland and Labrador",
                "NT": "Northwest Territories",
                "NS": "Nova Scotia",
                "NU": "Nunavut",
                "ON": "Ontario",
                "PE": "Prince Edward Island",
                "QC": "Quebec",
                "SK": "Saskatchewan",
                "YT": "Yukon Territory",
            }
        }
    }
}
</script>
